import './index.scss'
export default {
  name: "printTable",
  props: {
    renderOption: {type: Array, required: true},
    data: {type: Array},
    loading: {type: Boolean, default: false},
    width: {type: String, default: '100%'},
    buttonText: {type: String, default: '打印'},
    buttonType: {type: String, default: 'ok'},
    printId: {type: String, default: 'print'},
    callback: {type: Function, default: function () {
        console.log('关闭了打印工具')
    }},

  },

  data() {
    return {
      print: {
        id: this.printId,
        popTitle: '', // 打印配置页上方的标题this.$store.state.company.companyName
        extraHead: '', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
        previewTitle: '', // 打印预览的标题
        closeCallback:() => {
          this.callback()
        }
      },
    }
  },

  render() {
    return (
      <div>
        <ch-button
          v-print={this.print}
          loading={this.loading}
          ref={'printRef'}
          type={this.buttonType}>{this.buttonText}</ch-button>
        <div id={this.printId} class={'ch__print__table'} style={{width: this.width}}>
          <header>
            {this.$slots.header}
          </header>
          <main>
            <table width={'100%'}>
              {this.$slots.tablepre}
              <tr>
                {this.renderTableHeader()}
              </tr>
              {this.renderTableMain()}
            </table>
          </main>
          <footer>
            {this.$slots.footer}
          </footer>
        </div>
      </div>
    )
  },

  methods: {
    renderTableHeader() {
      return this.renderOption.filter(i => i.column !== 'slot').map((item) => (<td width={item.width || '70'}>
        {item.label}
      </td>))
    },
    renderTableMain() {
      return [
        ...this.data.map((data, index) => (<tr>
          {
            this.renderOption.filter(i => i.column !== 'slot').map((col) => {
              return col.column === 'index' ? <td width={ col.width || '70' }>{ index+1 }</td> : this.handleRenderList(col, data)
            })
          }
        </tr>)),
        this.$slots.tablesuf,
      ]
    },
    // 判断type并渲染对应
    handleRenderList({column, ...option}, data) {
      let itemElement
      const typeToRender = (column, option, data) => {
        switch (column) {
          case 'text': itemElement = this.renderText(option, data)
            break
          case 'cash': itemElement = this.renderCash(option, data)
            break
        }
      }
      typeToRender(column, option, data)
      return itemElement
    },

    // 渲染文本
    renderText({prop, no = '', width='70'}, data) {
      return (
        <td width={width}>
          { data[prop] ? data[prop] : no }
        </td>
      )
    },

    // 渲染金额
    renderCash({prop, no = '0.00', width='70', precision=2}, data) {
    //defaul 默认具名插槽
      return (<td width={width}>
        {
          (data[prop] === '' || data[prop] === null || data[prop] === undefined )
            ? <span>{no}</span>
            : <span>{Number(data[prop]).toFixed(precision)}</span>
        }
      </td>)
    },

    // 触发点击事件
    handleClickPrint() {
      this.$refs.printRef.$el.click()
    }


  }
}