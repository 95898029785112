<template>
  <div class="main_layout__wrapper ch_global_element_wrapper">
    <div class="layout__wrapper">

      <header class="search__wrapper" v-if="search">
        <div class="search__slot">
          <slot name="search"></slot>
        </div>
      </header>

      <header class="item__wrapper" v-if="item">
        <div class="item__slot">
          <slot name="item"></slot>
        </div>
      </header>

      <main class="admin__table">

        <div class="main__table__wrapper" :style="{
          boxShadow: tableShadow ? '0 0 3px rgba(0, 0, 0, 0.16)' : 'none'
        }">

          <div class="title__wrapper" v-if="title !== 'none'">
            {{title}}
          </div>

          <div class="button__wrapper" v-if="button">
            <slot name="button"></slot>
          </div>

          <div class="table__wrapper">
            <div>
              <slot></slot>
            </div>
          </div>

          <div class="page__wrapper" v-if="page">
            <p class="pag"><slot name="page"></slot></p>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Layout',
  props: {
    search: {
      type: Boolean,
      default: false
    },
    item: {
      type: Boolean,
      default: false
    },
    button: {
      type: Boolean,
      default: false
    },
    page: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'none'
    },
    tableShadow: {type: Boolean, default: true}
  }
}
</script>

<style lang="scss">
.main_layout__wrapper {
  padding-top: 15px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 100%;

  & .layout__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    & .search__wrapper {
      overflow: hidden;
      padding-bottom: 15px;

      & .search__slot{
        padding: 2vh 1vw;
        display: flex;
        border-radius: 3px;
        background: #FFFFFF;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);

        // TODO 可能会造成样式错误
        .search-wrapper {
          display: flex;
          width: 100%;
          .el-button + .el-button,
          div + .el-button,
          div + div {
            margin-left: 10px;
          }
        }
      }
    }
    & .item__wrapper {
      overflow: hidden;
      padding-bottom: 15px;

      & .item__slot{
        padding: 26px 22px;
        display: flex;
        border-radius: 3px;
        background: #FFFFFF;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
      }
    }

    & .admin__table {
      display: flex;
      flex: 1;
      height: 0;

      & .main__table__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border-radius: 3px;
        overflow: hidden;

        .title__wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          height: 80px;
          border-bottom: 1px solid #EEEEEE;
        }

        .button__wrapper {
          padding: 20px 20px 0px 20px;
        }

        .table__wrapper {
          flex: 1;
          overflow: hidden;

          &>div {
            padding: 10px;
            height: 100%;
            width: 100%;
          }

        }

        & .page__wrapper {
          position: relative;
          display: flex;
          height: 60px;

          &>.pag {
            position: relative;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

        }
      }
    }
  }
}
</style>