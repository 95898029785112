<template>
  <div class="code_down_wrapper" :style="{width: width}">
    <span v-show="show" class="slot" @click="getCode" v-on="$listeners"><slot/></span>
    <span v-show="!show" class="count">{{countCopy}} s</span>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 30
    },
    isPhone: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: '获取验证码'
    },
    width: {
      type: String,
      default: '100px'
    }
  },
  data(){
    return {
      show: true,
      timer: null,
      countCopy: 30
  }
  },
  methods:{
    getCode(){
      if(this.isPhone) {
        const TIME_COUNT = this.count;
        this.countCopy = this.count;
        if (!this.timer) {
          this.show = false;
          this.timer = setInterval(() => {
            if (this.countCopy > 0 && this.countCopy <= TIME_COUNT) {
              this.countCopy--
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
      } else {
        this.$message.error('请输入正确手机号')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.code_down_wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  .slot {color: #255ed7;}
  .count {
    color: #9C9EA7;
  }
}
</style>